import { useContext } from 'react';
import { CompanyContext } from '../contexts/CompanyContext';
import { GetOtherReviews } from './ThankYouPageOptions/GetOtherReviews';
import { RedirectToUrl } from './ThankYouPageOptions/RedirectToUrl';
import { FallbackThankYouPage } from './ThankYouPageOptions/FallbackThankYouPage';
import { ThankYouShare } from './ThankYouPageOptions/ThankYouShare';
import { ReviewContext } from '../contexts/ReviewContext';
import { CompanyThankYou } from '../types/ApiTypes';
import { ReferralForm } from "./ThankYouPageOptions/ReferralForm";
import {clarityEvent} from "../services/UtilService";
import { InviteContext } from '../contexts/InviteContext';
import { FeedbackThankYouPage } from './ThankYouPageOptions/FeedbackThankYouPage';

export function ThankYouPage({ isFeedback = false }: { isFeedback?: boolean }): JSX.Element {
  const { company } = useContext(CompanyContext);
  const { review } = useContext(ReviewContext);
  const { invite } = useContext(InviteContext);

  clarityEvent("Completed Review")

  const getThankYouPageFormat = (): CompanyThankYou['sharingFormat'] | null => {
    if (!company) return null;
    const thankYouSetting = company.thankYou.sharingFormat;
    // If a review exists, determine the thank-you page format based on rating
    if (review) {
      return review.stars >= company.minStarAllowExternal ? thankYouSetting : null;
    }
    // check invite settings for if the user clicked external button 
    if (invite && invite.settings && invite.settings.capture_review_platform) {
      return invite.settings.capture_review_platform !== "SoTellUs" ? thankYouSetting : null;
    }  
    return null;
  };

  const thankYouPageFormat: CompanyThankYou['sharingFormat'] = getThankYouPageFormat() ?? null;

  if (invite?.type === 'feedback' || isFeedback) {
    return <FeedbackThankYouPage />;
  }

  if (thankYouPageFormat == 0) {
    return <GetOtherReviews />;
  } else if(thankYouPageFormat == 1) {
    return (
        <ThankYouShare url={review?.reviewUrl ? review.reviewUrl : "https://sotellus.com"} isOffer={false}/>
    )
  } else if (thankYouPageFormat == 2) {
    return (
      <ThankYouShare
        url={review?.reviewUrl ? review.reviewUrl + 'offer/' : 'https://sotellus.com'}
        isOffer={thankYouPageFormat === 2 ? true : false}
      />
    );
  } else if(thankYouPageFormat == 3) {
    return (
        <ReferralForm
           url={review?.reviewUrl ? review.reviewUrl : 'https://sotellus.com'}
        />
    )
  } else if (thankYouPageFormat == 4) {
    return (
      <RedirectToUrl
        url={
          company?.thankYou?.redirectUrl
            ? company.thankYou.redirectUrl
            : 'https://sotellus.com'
        }
      />
    );
  } else {
    return <FallbackThankYouPage />;
  }
}
