import { Button, Rating } from '@mantine/core';
import { RatingChangeBoxProps } from '../types/PropsTypes';
import {useContext, useState} from 'react';
import {updateReview} from "../services/SoTellUsApiService";
import {ReviewContext} from "../contexts/ReviewContext";

export function RatingChangeBox({
  rating,
  onChange,
}: RatingChangeBoxProps): JSX.Element {
  const [disabled, setDisabled] = useState(true);
  const { review, setReview } = useContext(ReviewContext);

  const onUpdate = (newRating: number) => {
    if (newRating !== rating) {
      if (review) {
        setReview({...review, stars: newRating});
      }
      onChange(newRating);
    }
    setDisabled(true);
  };

  return (
    <div className="centered-group">
      <Rating
        readOnly={disabled}
        size={45}
        value={rating}
        onChange={(num)=>{
            updateReview({
                reviewId: review?.reviewId ?? "",
                rating: num
            }, (localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')!.length > 0) ? localStorage.getItem('jwtToken')! : "")
            onUpdate(num)
        }}
      />
      <div className="change-rating-btn">
        <Button
          onClick={() => setDisabled(false)}
          style={{
            visibility: disabled ? 'visible' : 'hidden',
            textWrap: 'wrap',
          }}
          variant={'light'}
          color={'gray.7'}
          ml={'md'}
          radius={'lg'}
          size="sm"
        >
          Change Rating
        </Button>
      </div>
    </div>
  );
}
