import { hasLength, useForm } from '@mantine/form';
import { WrittenReviewForm } from '../types/FormTypes';
import { WrittenReviewProps } from '../types/PropsTypes';
import { RatingChangeBox } from './RatingChangeBox';
import { useCallback, useRef, useState } from 'react';
import { Box, Button, Textarea } from '@mantine/core';
import ReCAPTCHA from 'react-google-recaptcha';
import RecaptchaText from './recpatcha/RecaptchaText';
export function STUWrittenReview({
  rating,
  companyName,
  onSubmit,
  isHipaa,
}: WrittenReviewProps): JSX.Element {
  const recaptchaRef = useRef<ReCAPTCHA|null>(null);
  const [currentRating, setCurrentRating] = useState(rating);
  const [submitting, setSubmitting] = useState(false);
  const form = useForm<WrittenReviewForm>({
    initialValues: {
      review: '',
    },
    validate: {
      review: hasLength(
        { min: 4, max: 3000 },
        'Review must be more than 4 characters long and less than 3000 characters',
      ),
    },
  });

  const hipaa_content = isHipaa ? " By providing this review, I consent to "+ companyName +" using my comments on their website and other promotional materials. I understand that my review may be de-identified to protect my privacy, and I have the right to withdraw my consent at any time by contacting "+ companyName +"." : "";
  const legalJargon = `
    By submitting your review to SoTellUs, you authorize the unrestricted use of your review by SoTellUs and by the person/entity that requested the review. 
    You hereby grant the unrestricted, irrevocable, worldwide, royalty-free right and license to copyright and use, re-use, publish, and republish photos, 
    videos, and/or audio recordings of me or in which I may be included, intact or in part, in composite or distorted in character or form, without restriction 
    as to changes, for unlimited purposes (both commercial and non-commercial), and through any and all media and methods of distribution, now known or hereafter 
    developed. All rights of privacy and publicity are hereby waived, including the right to use my name with my photos and/or videos. ${hipaa_content}
  `;

  const getDescriptor = useCallback(
    (rating: number): string => {
      if (rating < 4) {
        return "didn't love";
      } else if (rating < 5) {
        return 'like';
      } else {
        return 'LOVE';
      }
    },
    [currentRating],
  );

  return (
    <>
      <RatingChangeBox rating={currentRating} onChange={setCurrentRating} />
      <form
        className="centered-stack"
        data-clarity-unmask={true}
        onSubmit={form.onSubmit(async(values) => {
          setSubmitting(true);
          // we will always send a recaptcha but it is only necessary for anonymous reviews right now.  The api will check if it is anonymous and then check if it has valid recaptcha
          let recaptchaToken=null;
          try {
            if (recaptchaRef.current) {
              recaptchaRef.current.reset();
              recaptchaToken = await recaptchaRef.current.executeAsync();
            }
          } catch(e){}
          if (isHipaa) {
            onSubmit(values.review, currentRating, legalJargon, recaptchaToken);
          } else {
            onSubmit(values.review, currentRating, undefined, recaptchaToken);
          }
        })}
      >
        <h4 className="header-text">
          How would you describe your experience <br/>with {companyName}?
        </h4>
        <Textarea
          styles={{
            input: { minHeight: '125px' },
          }}
          w="100%"
          placeholder={`I ${getDescriptor(currentRating)} ${companyName} because...`}
          {...form.getInputProps('review')}
          mb={10}
        />
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY}
        />
        <Button
          type="submit"
          w={'100%'}
          color="green.7"
          size="md"
          radius={'sm'}
          loading={submitting}
        >
          Submit
        </Button>
        <Box mt={10}>
            <RecaptchaText />
        </Box>
        <p className="legal-jargon">
          {legalJargon}
        </p>
      </form>
    </>
  );
}
